import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import { ITestimonials } from '@/interfaces/testimonies';
import { getTestimonialIcon } from '@/shared/helpers';

type Props = {
  testimonials: ITestimonials;
};

const Testimonials = (props: Props) => {
  const { testimonials } = props;
  const { subTitle, testimonial, title } = testimonials;

  return (
    <div role="region" className="home-testimonials">
      <div className="home-alternative-testimonials-container">
        <div className="home-testimonials-info">
          <h2
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: subTitle,
            }}
          />
        </div>

        <Swiper
          loop={true}
          navigation={true}
          modules={[Pagination, Navigation]}
          centeredSlides={true}
          className="testimonials-slide"
          breakpoints={{
            1285: {
              slidesPerView: 3,
              spaceBetween: 64,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 32,
            },
            500: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
          }}
        >
          {testimonial?.map((testimony) => (
            <SwiperSlide key={testimony.name}>
              <section aria-label="testimony card" className="testimony-card">
                <div className="testimony-card-header">
                  <div className="testimony-image">
                    <img src={testimony.photoUrl} alt="testimony photo" />
                  </div>
                  <h2>{testimony.title}</h2>
                  <div
                    className="testimony-description"
                    dangerouslySetInnerHTML={{ __html: testimony.text }}
                  />
                </div>

                <div className="testimony-card-footer">
                  <h2>{testimony.name}</h2>
                  <span>{testimony.date}</span>
                  {testimony.socialMediaUrl || testimony.socialMediaIcon ? (
                    <a target="_blank" href={testimony.socialMediaUrl}>
                      <img
                        src={getTestimonialIcon(testimony.socialMediaIcon)}
                        alt={testimony.socialMediaIcon}
                      />
                    </a>
                  ) : null}
                </div>
              </section>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonials;
