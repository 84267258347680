import React from 'react';

import Map from '@/components/Map';
import HowItWorks from '@/components/HowItWorks';
import { IPageContext } from '@/interfaces/pageContext';
import { hasPropertiesWithValue } from '@/shared/helpers';
import { IFinalProductProps } from '@/interfaces/products';
import { DecideHomeDisplay } from 'src/templates/Home/utils';
import { changeLocation } from '@/shared/helpers';

import KnowMore from './components/KnowMore';
import ProductList from './components/ProductList';
import Testimonials from './components/Testimonials';
import HighLightText from './components/HighLightText';
import ContactsAndAddress from './components/ContactsAndAddress';
import './style.scss';

type Props = {
  pageContext: IPageContext;
  display: ReturnType<typeof DecideHomeDisplay>;
  products: IFinalProductProps[];
};

const DefaultHome = (props: Props) => {
  const { pageContext, display, products } = props;
  const { lang, appData, pathLang } = pageContext;
  const { templateData, locale } = appData;
  const { footer, home, testimonials } = templateData;
  const { homeKnowMore, homeHighLightText, productList } = home;
  const { address } = footer;
  const footerLocale = locale.footer;
  const howItWorksData = templateData.products.productHowItWorks;

  return (
    <article className="default-home-content">
      {display.showHighLightText &&
      hasPropertiesWithValue(homeHighLightText) ? (
        <HighLightText
          title={homeHighLightText?.title}
          description={homeHighLightText?.description}
        />
      ) : null}

      {hasPropertiesWithValue(homeKnowMore) ? (
        <KnowMore knowMore={homeKnowMore} onClick={changeLocation} />
      ) : null}

      {products.length > 0 ? (
        <ProductList
          home={home}
          locale={locale}
          pathLang={pathLang}
          products={products}
          productList={productList}
        />
      ) : null}

      {hasPropertiesWithValue(testimonials) ? (
        <Testimonials testimonials={testimonials} />
      ) : null}

      {hasPropertiesWithValue(howItWorksData) ? (
        <HowItWorks
          content={howItWorksData.content}
          subTitle={howItWorksData.subTitle}
          title={howItWorksData.title}
          type={display.type as 'default' | 'alternative'}
        />
      ) : null}

      {hasPropertiesWithValue(footerLocale) ||
      hasPropertiesWithValue(address) ? (
        <ContactsAndAddress address={address} footerLocale={footerLocale} />
      ) : null}

      {home.mapCoordinates ? (
        <Map
          className="home-address-map"
          coordinates={home.mapCoordinates}
          language={lang}
        />
      ) : null}
    </article>
  );
};

export default DefaultHome;
