import React from 'react';

import { IFooter } from '@/interfaces/footer';
import clockIcon from '@/static/images/clock-dark.svg';
import phoneIcon from '@/static/images/phone-dark.svg';
import mailIcon from '@/static/images/mail-dark.svg';
import mapPinIcon from '@/static/images/map-pin-dark.svg';
import { hasPropertiesWithValue } from '@/shared/helpers';
import { IFooterLocale } from '@/interfaces/staticlocale';
import whatsappIcon from '@/static/images/Whatsapp-dark.svg';
import { phoneCleaner, whatsappURL } from '@/lib/TelephoneFormatter';
import Map from '@/components/Map';

type Props = {
  footer: IFooter;
  language: string;
  coordinates?: string;
  footerLocale: IFooterLocale;
};

const ContactsAndAddress = (props: Props) => {
  const { footerLocale, footer, language, coordinates } = props;
  const {
    whatsapp_icon,
    contacts,
    telephone_number_icon,
    email_icon,
    address_map_pin_icon,
    clock_icon,
  } = footerLocale;
  const { address, phone, whatsapp, whatsappMessage, email, schedules } = footer;

  return (
    <div role="region" className="home-address-and-map-container">
      {hasPropertiesWithValue(footerLocale) ||
      hasPropertiesWithValue(address) ? (
        <div role="region" className="home-address-container">
          <h1>{contacts}</h1>

          <div className="home-address-info-container">
            {hasPropertiesWithValue({ whatsapp, phone }) ? (
              <div className="home-address-info">
                <h2>{contacts}</h2>
                <div className="home-address-content-conteiner">
                  {whatsapp ? (
                    <a
                      className="home-address-content"
                      target="_blank"
                      href={whatsappURL(whatsapp, whatsappMessage)}
                    >
                      <img src={whatsappIcon} alt={whatsapp_icon} />
                      <p>{whatsapp}</p>
                    </a>
                  ) : null}
                  {phone ? (
                    <a
                      className="home-address-content"
                      href={`tel:${phoneCleaner(phone)}`}
                    >
                      <img src={phoneIcon} alt={telephone_number_icon} />
                      <p>{phone}</p>
                    </a>
                  ) : null}
                  {email ? (
                    <a
                      className="home-address-content"
                      href={`mailto:${email}`}
                    >
                      <img src={mailIcon} alt={email_icon} />
                      <p>{email}</p>
                    </a>
                  ) : null}
                </div>
              </div>
            ) : null}

            {hasPropertiesWithValue(address) ? (
              <div className="home-address-info">
                {footerLocale?.address ? <h2>{footerLocale.address}</h2> : null}
                <div className="home-address-content">
                  <img src={mapPinIcon} alt={address_map_pin_icon} />
                  <div>
                    <p>
                      {address?.city} - {address?.state}
                    </p>
                    <p>
                      {address?.street}, {address?.number},{' '}
                      {address?.neighborhood}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            {schedules ? (
              <div className="home-address-info">
                <h2>{footerLocale.schedules}</h2>

                <div className="home-address-content">
                  <img src={clockIcon} alt={clock_icon} />
                  <p>{schedules}</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {coordinates ? (
        <Map
          language={language}
          coordinates={coordinates}
          className="home-address-map"
        />
      ) : null}
    </div>
  );
};

export default ContactsAndAddress;
