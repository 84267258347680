import React from 'react';

import { IHomeKnowMore } from '@/interfaces/home';
import ImagesSlide from '@/components/ImagesSlide';
import { track } from '@/shared/analytics';

type Props = {
  knowMore: IHomeKnowMore;
  onClick: (value: string) => void;
};

const KnowMore = (props: Props) => {
  const { knowMore, onClick } = props;
  const {
    homeKnowMoreSlideImages,
    homeKnowMoreText,
    homeKnowMoreTitle,
    homeKnowMoreButton,
    homeKnowMoreButtonLink,
  } = knowMore;

  return (
    <div role="region" aria-label="know more" className="know-more-container">
      <div className="infos">
        <h2
          dangerouslySetInnerHTML={{
            __html: homeKnowMoreTitle,
          }}
        />
        {homeKnowMoreText ? (
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: homeKnowMoreText,
            }}
          />
        ) : null}
        {homeKnowMoreButton ? (
          <button
            className="action button-w-225px"
            onClick={() => {
              onClick(homeKnowMoreButtonLink ?? '');
              track('homePageKnowMoreButtonClick', { template: 'virgoA' });
            }}
          >
            {homeKnowMoreButton}
          </button>
        ) : null}
      </div>

      {homeKnowMoreSlideImages?.length > 0 ? (
        <ImagesSlide images={homeKnowMoreSlideImages} />
      ) : null}
    </div>
  );
};

export default KnowMore;
