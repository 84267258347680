import React from 'react';

import { IAddress } from '@/interfaces/footer';
import { IFooterLocale } from '@/interfaces/staticlocale';
import mapPinIcon from '@/static/images/map-pin-dark.svg';

type Props = {
  footerLocale: IFooterLocale;
  address: IAddress;
};

const ContactsAndAddress = (props: Props) => {
  const { footerLocale, address } = props;
  const { address_map_pin_icon, location } = footerLocale;

  return (
    <div role="region" className="home-address-container">
      <h1
        dangerouslySetInnerHTML={{
          __html: location,
        }}
      />

      <div className="home-address-info">
        <h2>{footerLocale.address}</h2>
        {address ? (
          <div className="home-address-content">
            <img src={mapPinIcon} alt={address_map_pin_icon} />
            <div>
              <p>
                {address?.city} - {address?.state}
              </p>
              <p>
                {address?.street}, {address?.number}, {address?.neighborhood}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ContactsAndAddress;
