import React from 'react';

type Props = {
  className?: string;
  coordinates: string;
  language: string;
};

const Map = (props: Props) => {
  const { className, coordinates, language } = props;

  return (
    <div className={className}>
      <iframe
        frameBorder={0}
        scrolling="no"
        marginHeight={0}
        marginWidth={0}
        src={`https://maps.google.com/maps?q=${coordinates}&hl=${language}&z=14&output=embed`}
      />
    </div>
  );
};

export default Map;
