import React from 'react';

import ProductCard from '@/components/ProductCard';
import { IHome, IProductList } from '@/interfaces/home';
import { IStaticLocale } from '@/interfaces/staticlocale';
import { IFinalProductProps } from '@/interfaces/products';
import { hasPropertiesWithValue, mountUrl } from '@/shared/helpers';

type Props = {
  home: IHome;
  pathLang: string;
  locale: IStaticLocale;
  productList: IProductList;
  products: IFinalProductProps[];
};

const ProductList = (props: Props) => {
  const { productList, products, home, locale, pathLang } = props;
  const {
    productListingPage,
    homeProductListTitle,
    productListingPageCta,
    homeProductListSubTitle,
  } = productList;

  return (
    <div role="region" className="home-other-products">
      {hasPropertiesWithValue(productList) ? (
        <div className="home-other-products-info">
          <h2
            dangerouslySetInnerHTML={{
              __html: homeProductListTitle,
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: homeProductListSubTitle,
            }}
          />
        </div>
      ) : null}

      <div className="home-other-products-list">
        {products.map((product) => (
          <ProductCard
            key={product.id}
            home={home}
            locale={locale}
            product={product}
            hasAction
          />
        ))}
      </div>

      {productListingPage ? (
        <a href={mountUrl(`${pathLang}produtos`)}>
          <button>{productListingPageCta}</button>
        </a>
      ) : null}
    </div>
  );
};

export default ProductList;
