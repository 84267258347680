import React from 'react';
import { track } from '@/shared/analytics';

import { IHome } from '@/interfaces/home';
import { IFinalProductProps } from '@/interfaces/products';
import processBackgroundWithOpacity from '@/lib/ProcessImageWithOpacity';

import './style.scss';

type Props = {
  home: IHome;
  mainProduct?: IFinalProductProps;
  pathLang: string;
};

const HighlightContent = (props: Props) => {
  const { home, mainProduct, pathLang } = props;
  const productUrl = mainProduct?.currentPath?.substring(1);

  return (
    <div className="highlight-container">
      {home.homeBgType === 'video' && home.homeBgVideoFormats.length > 0 ? (
        <div className="video-container">
          <div
            style={{
              backgroundImage: processBackgroundWithOpacity({
                opacity: home.opacity,
              }),
            }}
            className="video-section-background-opacity"
          />
          <video
            className="intro-video"
            autoPlay
            loop
            muted
            data-wf-ignore="true"
          >
            {home.homeBgVideoFormats.map(({ url, type }, index) => (
              <source key={index} src={url} data-wf-ignore="true" type={type} />
            ))}
          </video>
        </div>
      ) : null}
      <div className="default-screen-size highlight-content">
        <h2
          dangerouslySetInnerHTML={{
            __html: home?.homeHeadline,
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: home?.homeSubline,
          }}
        />
        <section aria-label="actions" className="highlight-actions">
          <a
            href={`/${pathLang}${productUrl}`}
            onClick={() =>
              track('homePagePrimaryButtonClick', {
                template: 'virgoA',
                link: `/${pathLang}${productUrl}`,
              })
            }
          >
            <button>{home?.homeCta}</button>
          </a>
          {home?.homeSecondCta ? (
            <a
              href={home.homeSecondCta?.url}
              onClick={() =>
                track('homePageSecondaryButtonClick', {
                  template: 'virgoA',
                  link: home.homeSecondCta?.url,
                })
              }
            >
              <button
                className="secondary-action"
                style={{
                  color: home.homeSecondCta?.color,
                  borderColor: home.homeSecondCta?.color,
                }}
              >
                {home.homeSecondCta?.title}
              </button>
            </a>
          ) : null}
        </section>
      </div>
    </div>
  );
};

export default HighlightContent;
