import React from 'react';

type Props = {
  title?: string;
  description?: string;
};

const HighLightText = (props: Props) => {
  const { description, title } = props;

  return (
    <div
      role="region"
      aria-label="highlight text"
      className="highlight-text-container"
    >
      {title ? (
        <h2
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      ) : null}
      {description ? (
        <p
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      ) : null}
    </div>
  );
};

export default HighLightText;
