import React, { useEffect } from 'react';

import { getHomeProductList } from '@/shared/helpers';
import { IPageContext } from '@/interfaces/pageContext';
import { DefaultLayout } from 'src/layouts/DefaultLayout';
import { DecideHomeDisplay } from 'src/templates/Home/utils';

import 'swiper/css';
import 'swiper/css/navigation';

import DefaultHome from './DefaultHome';
import AlternativeHome from './AlternativeHome';
import HighlightContent from './HighlightContent';
import { track } from '@/shared/analytics';

type Props = {
  pageContext: IPageContext;
};

const Home = ({ pageContext }: Props) => {
  const { pathLang } = pageContext;
  const { products, templateData } = pageContext.appData;
  const { home } = templateData;

  const display = DecideHomeDisplay(home.homeDisplay || 'default');

  const defaultLayoutData = {
    pageContext,
    templateData,
    title: '',
    display: display.type,
    domNodeToExtractHeight: '.highlight-container',
    isHomePage: true,
  };

  const mainProduct = products.find(
    (product) => product.id === home.mainProductId
  );

  const allowedProducts = getHomeProductList(
    products,
    home.productList,
    home.notListedProducts,
    home.mainProductId
  );

  useEffect(() => {
    track('homePageView', { template: 'virgoA' });
  }, []);

  return (
    <DefaultLayout {...defaultLayoutData}>
      <HighlightContent
        pathLang={pathLang}
        home={home}
        mainProduct={mainProduct}
      />
      {display.type === 'default' ? (
        <DefaultHome
          products={allowedProducts}
          pageContext={pageContext}
          display={display}
        />
      ) : (
        <AlternativeHome
          products={allowedProducts}
          pageContext={pageContext}
          display={display}
        />
      )}
    </DefaultLayout>
  );
};

export default Home;
