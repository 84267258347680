import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';

import './style.scss';

type Props = SwiperProps & {
  images: { url: string }[];
};

const ImagesSlide = ({ images, modules, ...props }: Props) => {
  const customModules = modules && modules?.length > 0 ? modules : [];

  return (
    <Swiper
      rewind={true}
      navigation={true}
      slidesPerView={1}
      modules={[Navigation, ...customModules]}
      className="custom-swiper-container"
      {...props}
    >
      {images.map((image, index) => (
        <SwiperSlide key={image.url}>
          <img src={image.url} alt={`slide ${index + 1}`} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ImagesSlide;
