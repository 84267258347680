import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import Testimony from '@/components/Testimony';
import { ITestimonials } from '@/interfaces/testimonies';
import { getTestimonialsSlidesBreakPoints } from '@/shared/helpers';

type Props = {
  testimonials: ITestimonials;
};

const Testimonials = (props: Props) => {
  const { testimonials } = props;
  const { subTitle, testimonial, title } = testimonials;

  return (
    <div role="region" className="home-testimonials">
      <div className="slides-shadow rigth-shadow" />
      <div className="home-testimonials-info">
        <h2
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: subTitle,
          }}
        />
      </div>

      <Swiper
        loop={true}
        navigation={true}
        modules={[Pagination, Navigation]}
        centeredSlides={true}
        className="mySwiper"
        breakpoints={getTestimonialsSlidesBreakPoints(testimonial)}
      >
        {testimonial.map((testimony) => (
          <SwiperSlide key={testimony.name}>
            <Testimony testimony={testimony} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="slides-shadow left-shadow" />
    </div>
  );
};

export default Testimonials;
