import React from 'react';

import { ITestimonialContent } from '@/interfaces/testimonies';

import './style.scss';
import { track } from '@/shared/analytics';
import { getTestimonialIcon } from '@/shared/helpers';

type Props = {
  testimony: ITestimonialContent;
};

const Testimony = (props: Props) => {
  const { testimony } = props;
  const { photoUrl, title, text, name, date, socialMediaUrl, socialMediaIcon } =
    testimony;

  return (
    <div className="testimony-container">
      <div className="testimony-content">
        <div className="testimony-top-content">
          <div className="testimony-image">
            <img src={photoUrl} alt="" />
          </div>

          <div className="testimony-info">
            <h2 className="title typ-7">{title}</h2>
            <div
              className="description typ-8"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        </div>

        <div className="testimony-bottom-content">
          <h3>{name}</h3>
          <p>{date}</p>
          {socialMediaUrl || socialMediaIcon ? (
            <a
              style={{ cursor: 'pointer' }}
              target="_blank"
              href={socialMediaUrl}
              onClick={() =>
                track('testimonyClick', {
                  template: 'virgoA',
                  link: socialMediaUrl,
                })
              }
            >
              <img
                src={getTestimonialIcon(socialMediaIcon)}
                alt={socialMediaIcon}
              />
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Testimony;
